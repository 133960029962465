import React, { useState } from "react";
import { Button, Form, Input, Modal, Spin, Empty } from "antd";
import { useTranslation } from "react-i18next";
import { PlusOutlined, UserOutlined } from "@ant-design/icons";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { resellerComments } from "../../../network/network";
import moment from "moment";

const ResellerNotes = ({ notesData, resellerId, reloadCallback }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [noteForm] = Form.useForm();

  const showModal = () => {
    // Show Modal
    setModalVisible(true);
    // Reset Form Fields
    noteForm.resetFields();
    // Set Form Fields
    noteForm.setFieldsValue({
      reseller_id: resellerId,
    });
  };

  const handleAddEdit = () => {
    noteForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);
        // Sending Request to API
        resellerComments(values)
          .then((response) => {
            let data = response.data || response.response.data;
            if (data.success === true) {
              // Success
              NotificationWithIcon(
                "success",
                t("note_added"),
                t("note_added_successfully")
              );
              reloadCallback();
              noteForm.resetFields();
              setModalVisible(false);
            } else {
              let errors = data?.data?.errors;
              if (errors && Object.keys(errors).length) {
                // Error
                NotificationWithIcon(
                  "error",
                  t("something_went_wrong"),
                  errors[Object.keys(errors)[0]]
                );
              }
            }
          })
          .catch((info) => {
            console.log("API Failed:", info.response);
            // Error
            NotificationWithIcon(
              "error",
              t("something_went_wrong"),
              info.response.data.data.errors.name
            );
          })
          .finally(() => {
            // Set Loading False
            setLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    // Reset Form Fields
    noteForm.resetFields();
    // close modal
    setModalVisible(false);
  };

  return (
    <>
      <Spin spinning={loading}>
        <div style={{ marginBottom: "20px", textAlign: "right" }}>
          <Button type="primary" onClick={showModal}>
            <PlusOutlined title={t("note_add")} /> {t("note_add")}
          </Button>
        </div>
        {notesData ? (
          <>
            {notesData.map((note) => (
              <div
                className="notes_card"
                style={{
                  boxShadow: "rgba(154, 154, 154, 0.537) 0 0 20px -5px",
                  margin: "20px",
                  borderRadius: "10px",
                }}
                key={note.id}
              >
                <div
                  className="notes_head"
                  style={{
                    padding: "20px",
                    borderBottom: "1px solid #e2e8f0",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        display: "flex",
                        flexGrow: "1",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ marginRight: "10px" }}>
                        <UserOutlined style={{ fontSize: "18px" }} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexGrow: "1",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            marginRight: "5px",
                          }}
                        >
                          {note.created_by.name}
                        </span>
                        left a note on
                        <span
                          style={{
                            fontWeight: "bold",
                            marginLeft: "5px",
                          }}
                        >
                          {moment(note.created_at).format("MMM. D, YYYY HH:mm")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="notes_body" style={{ padding: "20px" }}>
                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <div
                      style={{
                        textAlign: "justify",
                      }}
                    >
                      {note.description}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <Empty />
        )}
        {/* add or edit note */}
        <Modal
          open={modalVisible}
          title={t("note_add")}
          onOk={handleAddEdit}
          onCancel={handleCancel}
          footer={[
            <Button key="back" loading={loading} onClick={handleCancel}>
              {t("cancel")}
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleAddEdit}
            >
              {t("note_add")}
            </Button>,
          ]}
        >
          <Form
            name="note-form"
            layout="vertical"
            onFinish={handleAddEdit}
            style={{ width: "100%", margin: "0" }}
            form={noteForm}
          >
            <Form.Item
              name="description"
              label={t("description")}
              rules={[
                { required: true, message: t("validate_description_required") },
              ]}
            >
              <Input.TextArea rows={5} />
            </Form.Item>
            <Form.Item name="reseller_id" style={{ display: "none" }}>
              <input type="hidden" />
            </Form.Item>
          </Form>
        </Modal>
      </Spin>
    </>
  );
};

export default ResellerNotes;
