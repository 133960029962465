import React, { useContext } from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { currencyFormatter } from "../../../utils";
import { AppContext } from "../../../context/AppContextProvider";

const BalanceDetails = ({ data }) => {
  const { setActiveMenu } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const tableColumns = [
    {
      title: "#",
      key: "index",
      width: "50px",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("business_name"),
      render: (record) => {
        return (
          <>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setActiveMenu("companies");
                navigate({
                  pathname: "/companies/" + record.company_id,
                });
              }}
            >
              {record.company_name}
            </span>
          </>
        );
      },
    },
    {
      title: t("amount"),
      render: (payout) => {
        return <>{currencyFormatter.format(payout.amount)}</>;
      },
    },
  ];

  return (
    <>
      <Table
        columns={tableColumns}
        dataSource={data}
        rowKey="id"
        pagination={false}
        summary={(pageData) => {
          let totalSales = 0;

          pageData.forEach(({ amount }) => {
            totalSales = totalSales + amount;
          });

          return (
            <>
              <Table.Summary.Row
                style={{ backgroundColor: "#fafafa", fontWeight: "bold" }}
              >
                <Table.Summary.Cell colSpan={2}>
                  {t("total")}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  {currencyFormatter.format(totalSales)}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </>
  );
};

export default BalanceDetails;
