import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Tag,
  Row,
  Col,
  Tabs,
  Spin,
  Modal,
  InputNumber,
  Form,
  Input,
  Space,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  HomeOutlined,
  EyeOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getReseller, resellerPayNow } from "../../../network/network";
import { currencyFormatter } from "../../../utils";
import CustomImageBox from "../../../components/CustomImageBox";
import PayoutHistory from "./PayoutHistory";
import BankDetails from "./BankDetails";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import LevelConfiguration from "./LevelConfiguration";
import {
  RESELLER_STATUS_ACTIVE,
  RESELLER_STATUS_INACTIVE,
  RESELLER_STATUS_KYC,
  RESELLER_STATUS_REJECTED,
  RESELLER_STATUS_REVIEW,
} from "../../../configs/constants";
import BalanceDetails from "./BalanceDetails";
import ResellerActions from "./ResellerActions";
import ResellerNotes from "./ResellerNotes";

const ResellerDetail = () => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  let { resellerId } = useParams();
  const [payoutForm] = Form.useForm();

  useEffect(() => {
    setIsDataLoading(true);
    getReseller(resellerId)
      .then((response) => {
        setTableData(response.data.data.reseller);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [reloadTableData, resellerId]);

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "/resellers",
      breadcrumbName: t("menu_resellers"),
      icon: <UsergroupAddOutlined />,
    },
    {
      path: "",
      breadcrumbName: t("menu_reseller_detail"),
      icon: <EyeOutlined />,
    },
  ];

  const showModal = () => {
    // Show Modal
    setModalVisible(true);
    // Reset Form Fields
    payoutForm.resetFields();
    // Set Form Fields
    payoutForm.setFieldsValue({
      amount: tableData.reseller_balance,
      account_number: tableData.bank_details.account_number,
    });
  };

  const handleCancel = () => {
    // Reset Form Fields
    payoutForm.resetFields();
    setModalVisible(false);
  };
  const handlePay = () => {
    payoutForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);
        // Sending Request to API
        resellerPayNow(resellerId, values)
          .then((response) => {
            let data = response.data || response.response.data;
            if (data.success === true) {
              // Success
              NotificationWithIcon(
                "success",
                t("payout_added"),
                t("payout_added_successfully")
              );
              setReloadTableData(!reloadTableData);
              payoutForm.resetFields();
              setModalVisible(false);
            } else {
              let errors = data?.data?.errors;
              if (errors && Object.keys(errors).length) {
                // Error
                NotificationWithIcon(
                  "error",
                  t("something_went_wrong"),
                  errors[Object.keys(errors)[0]]
                );
              }
            }
          })
          .catch((info) => {
            console.log("API Failed:", info.response);
            // Error
            NotificationWithIcon(
              "error",
              t("something_went_wrong"),
              info.response.data.data.errors.name
            );
          })
          .finally(() => {
            // Set Loading False
            setLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const tabItems = [
    {
      label: t("notes"),
      key: "notes",
      children: (
        <ResellerNotes
          notesData={tableData.comments}
          resellerId={resellerId}
          reloadCallback={() => setReloadTableData(!reloadTableData)}
        />
      ),
    },
    {
      label: t("bank_detail"),
      key: "bank-detail",
      children: (
        <BankDetails
          data={tableData.bank_details}
          resellerId={resellerId}
          reloadCallback={() => setReloadTableData(!reloadTableData)}
        />
      ),
    },
    {
      label: t("payout_history"),
      key: "payout-history",
      children: <PayoutHistory data={tableData.payout_history} />,
    },
    {
      label: t("level_config"),
      key: "level-config",
      children: (
        <LevelConfiguration
          data={tableData.level_configuration}
          resellerId={resellerId}
          reloadCallback={() => setReloadTableData(!reloadTableData)}
        />
      ),
    },
    {
      label: t("balance_details"),
      key: "balance-details",
      children: <BalanceDetails data={tableData.contributing_companies} />,
    },
  ];

  const requestReloadCallback = () => {
    setReloadTableData(!reloadTableData);
  };

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb items={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_reseller_detail")}
          onBack={() => window.history.back()}
          ghost={false}
          extra={false}
        />
      </Card>
      <Spin spinning={isDataLoading}>
        <Row gutter={[20, 20]}>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
            xxl={16}
            style={{ marginBottom: "20px" }}
          >
            <Card>
              <Row gutter={[20, 20]}>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("name")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {tableData.first_name ? tableData.first_name : "-"}{" "}
                    {tableData.last_name && tableData.last_name}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("email")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {tableData.email ? tableData.email : "-"}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("phone_number")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {tableData.phone ? tableData.phone : "-"}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("status")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {tableData.status === RESELLER_STATUS_ACTIVE ? (
                      <Tag color={"green"}>{t("active")}</Tag>
                    ) : tableData.status === RESELLER_STATUS_KYC ? (
                      <Tag color={"yellow"}>KYC</Tag>
                    ) : tableData.status === RESELLER_STATUS_REJECTED ? (
                      <Tag color={"orange"}>Rejected</Tag>
                    ) : tableData.status === RESELLER_STATUS_REVIEW ? (
                      <Tag color={"blue"}>In Review</Tag>
                    ) : tableData.status === RESELLER_STATUS_INACTIVE ? (
                      <Tag color={"red"}>Inactive</Tag>
                    ) : (
                      "-"
                    )}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("type")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {tableData.user_type ? tableData.user_type : "-"}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("reseller_number")}
                  </div>
                  <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
                    {tableData.reseller_number
                      ? tableData.reseller_number
                      : "-"}
                  </div>
                </Col>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={8}
                  xxl={8}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      paddingBottom: "10px",
                    }}
                  >
                    {t("photo_of_id")}
                  </div>
                  <Button
                    type="primary"
                    href={tableData.user_photo_id}
                    target="_blank"
                  >
                    <EyeOutlined />
                  </Button>
                </Col>
                {tableData.company_registration_document && (
                  <Col
                    className="gutter-row"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={8}
                    xxl={8}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        paddingBottom: "10px",
                      }}
                    >
                      {t("company_registration_document")}
                    </div>
                    <Button
                      type="primary"
                      href={tableData.company_registration_document}
                      target="_blank"
                    >
                      <EyeOutlined />
                    </Button>
                  </Col>
                )}
                {tableData.reseller_company_name && (
                  <Col
                    className="gutter-row"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={8}
                    xxl={8}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        paddingBottom: "10px",
                      }}
                    >
                      {t("company_name")}
                    </div>
                    <div
                      style={{ border: "1px solid #d9d9d9", padding: "10px" }}
                    >
                      {tableData.reseller_company_name}
                    </div>
                  </Col>
                )}
              </Row>
            </Card>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            xxl={8}
            style={{ marginBottom: "20px" }}
          >
            <Space size={20} direction="vertical" style={{ width: "100%" }}>
              <Card title={t("wallet_detail")}>
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      {t("available_balance")} <br />
                      <h2 style={{ margin: 0 }}>
                        {currencyFormatter.format(tableData.reseller_balance)}
                      </h2>
                    </div>
                    {tableData.reseller_balance > 0 &&
                      tableData.bank_details.account_number && (
                        <Button
                          type="primary"
                          style={{
                            backgroundColor: "#2e7c08",
                            border: "#2e7c08",
                          }}
                          onClick={showModal}
                        >
                          {t("pay_now")}
                        </Button>
                      )}
                  </div>
                </div>
              </Card>
              <Card title={t("col_action")}>
                <ResellerActions
                  reseller={tableData}
                  reloadCallback={requestReloadCallback}
                />
              </Card>
            </Space>
          </Col>
        </Row>
        <Row gutter={[20, 20]} style={{ marginBottom: "20px" }}>
          <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
            <CustomImageBox
              image="/images/icons/rank.png"
              title="Partner Rank"
              value={tableData.reseller_level}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
            <CustomImageBox
              image="/images/icons/unpaid-comission.png"
              title="Unpaid Comission"
              value={tableData.trial_customers_count}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
            <CustomImageBox
              image="/images/icons/paid-comission.png"
              title="Paid Comission"
              value={tableData.paid_customers_count}
            />
          </Col>
        </Row>
        <Card>
          <Tabs
            defaultActiveKey="notes"
            type="card"
            centered
            items={tabItems}
          />
        </Card>
      </Spin>
      <Modal
        open={modalVisible}
        title=""
        onOk={handlePay}
        onCancel={handleCancel}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handlePay}
          >
            {t("pay_now")}
          </Button>,
        ]}
      >
        <Form
          name="payout-form"
          layout="vertical"
          onFinish={handlePay}
          style={{ width: "100%", margin: "0" }}
          form={payoutForm}
        >
          <Form.Item
            name="amount"
            label={t("amount")}
            rules={[
              {
                required: true,
                message: t("validate_amount_required"),
              },
            ]}
            hasFeedback
          >
            <InputNumber disabled style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name="reference_number"
            label={t("reference_no")}
            rules={[
              {
                required: true,
                message: t("validate_reference_no_required"),
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          <Form.Item name="account_number" label={t("account_number")}>
            <Input disabled />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ResellerDetail;
