import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Table,
  Modal,
  Popconfirm,
  Radio,
  Tag,
  Row,
  Col,
  message,
  Upload,
  Select,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  HomeOutlined,
  EditTwoTone,
  DeleteTwoTone,
  EyeOutlined,
  UsergroupAddOutlined,
  UploadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  createReseller,
  updateReseller,
  getResellers,
  deleteReseller,
} from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import {
  resellerStatus,
  RESELLER_STATUS_ACTIVE,
  RESELLER_STATUS_INACTIVE,
  RESELLER_STATUS_KYC,
  RESELLER_STATUS_REJECTED,
  RESELLER_STATUS_REVIEW,
} from "../../../configs/constants";
import ImageUpload from "../../../components/ImageUpload";

const Reseller = () => {
  const ADD_RESELLER = "Add Reseller Agent";
  const [modalTitle, setModalTitle] = useState(ADD_RESELLER);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(false);
  const [validatePassword, setValidatePassword] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [userType, setUserType] = useState([]);
  const [userIDImage, setUserIDImage] = useState(false);
  const [companyRegistrationDoc, setCompanyRegistrationDoc] = useState(false);
  const [searchParams, setSearchParams] = useState([]);
  const [paginationData, setPaginationData] = useState({
    current: 1,
    pageSize: 15,
    total: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [resellerForm] = Form.useForm();
  const [searchFormRef] = Form.useForm();
  const { t } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    setIsDataLoading(true);
    getResellers({ ...searchParams, page: currentPage })
      .then((response) => {
        setTableData(response.data.data.resellers);
        setPaginationData(response.data.data.pagination);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [reloadTableData, searchParams, currentPage]);

  const showAddModal = () => {
    // Set Modal Title
    setModalTitle(t("reseller_add"));

    // Reset Form Fields
    resellerForm.resetFields();
    setUserType([]);
    setUserIDImage(false);
    setCompanyRegistrationDoc(false);

    // Show Modal
    setModalVisible(true);
    // Enable Password Validation
    setValidatePassword(true);
  };

  const handleAPIResponse = (data, type) => {
    if (data.success === true) {
      setReloadTableData(!reloadTableData);
      // Success
      if (type === ADD_RESELLER) {
        NotificationWithIcon(
          "success",
          t("reseller_added"),
          t("reseller_added_successfully")
        );
      } else {
        NotificationWithIcon(
          "success",
          t("reseller_updated"),
          t("reseller_updated_successfully")
        );
      }
    } else {
      // Error
      NotificationWithIcon("error", t("something_went_wrong"), data.message);
    }
    // Reset Form Fields
    resellerForm.resetFields();
    // Hide Modal
    setModalVisible(false);
  };

  const handleAddEdit = () => {
    if (!companyRegistrationDoc && userType === "company") {
      message.error(t("validate_company_registration_document_required"));
    } else if (!userIDImage) {
      message.error(t("validate_Photo_of_id_required"));
    } else {
      resellerForm
        .validateFields()
        .then((values) => {
          setLoading(true);
          // Set Form values as FormData
          const formData = new window.FormData();
          if (typeof userIDImage == "object" && userIDImage) {
            formData.append("user_photo_id", userIDImage);
          }
          if (
            typeof companyRegistrationDoc == "object" &&
            companyRegistrationDoc &&
            userType !== "individual"
          ) {
            formData.append(
              "company_registration_document",
              companyRegistrationDoc
            );
          }
          Object.keys(values).map((key) => {
            if (values[key] != null) {
              formData.append(key, values[key]);
            }
            return true;
          });
          // Sending Request to API
          if (modalTitle === ADD_RESELLER || modalTitle === t("reseller_add")) {
            createReseller(formData)
              .then((response) => {
                let data = response.data || response.response.data;
                if (data.success === true) {
                  return handleAPIResponse(data, ADD_RESELLER);
                } else {
                  let errors = data?.errors;
                  if (errors && Object.keys(errors).length) {
                    // Error
                    NotificationWithIcon(
                      "error",
                      t("something_went_wrong"),
                      errors[Object.keys(errors)[0]]
                    );
                  }
                }
              })
              .finally(() => {
                // Set Loading False
                setLoading(false);
              });
          } else {
            updateReseller(formData)
              .then((response) => {
                let data = response.data || response.response.data;
                if (data.success === true) {
                  return handleAPIResponse(data, null);
                } else {
                  let errors = data?.data?.errors;
                  if (errors && Object.keys(errors).length) {
                    // Error
                    NotificationWithIcon(
                      "error",
                      t("something_went_wrong"),
                      errors[Object.keys(errors)[0]]
                    );
                  }
                }
              })
              .finally(() => {
                // Set Loading False
                setLoading(false);
              });
          }
          // }
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
    }
  };

  const handleEditModal = (data) => {
    resellerForm.resetFields();
    // Set Modal Title
    data.first_name && data.last_name
      ? setModalTitle(
          t("reseller_edit") + " : " + data.first_name + " " + data.last_name
        )
      : setModalTitle(t("reseller_edit"));

    // Show Modal
    setModalVisible(true);
    // Set Form Fields
    resellerForm.setFieldsValue({
      id: data.id,
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
      email: data.email,
      user_type: data.user_type,
      reseller_company_name: data.reseller_company_name,
    });
    setUserType(data.user_type);
    setUserIDImage(data.user_photo_id);
    setCompanyRegistrationDoc(data.company_registration_document);
    // Disable Password Validation
    setValidatePassword(false);
    setModalData(data);
  };

  const handleCancel = () => {
    // Reset Form Fields
    resellerForm.resetFields();
    setModalVisible(false);
  };

  const handledelete = (id) => {
    deleteReseller(id)
      .then((response) => {
        if (response.data.success === true) {
          setReloadTableData(!reloadTableData);
          // Success
          NotificationWithIcon(
            "success",
            t("reseller_deleted"),
            t("reseller_deleted_successfully")
          );
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            response.data.message
          );
        }
      })
      .catch((info) => {
        // Error
        console.log("Delete error: ", info.response.data.message);
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          info.response.data.message
        );
      });
  };

  const passwordValueChanged = (e) => {
    if (
      e.target.value.length === 0 &&
      !(modalTitle === ADD_RESELLER || modalTitle === t("reseller_add"))
    ) {
      setValidatePassword(false);
    } else {
      setValidatePassword(true);
    }
  };

  function beforeUpload(file) {
    const is5MB = file.size / 1024 / 1024 < 5;
    if (!is5MB) {
      message.error(t("validate_file_size_5"));
    }
    return is5MB;
  }

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "",
      breadcrumbName: t("menu_resellers"),
      icon: <UsergroupAddOutlined />,
    },
  ];

  const tableColumns = [
    {
      title: "#",
      key: "index",
      align: "center",
      width: "50px",
      render: (text, record, index) => {
        return paginationData.current_page
          ? paginationData.per_page * (paginationData.current_page - 1) +
              (index + 1)
          : index + 1;
      },
    },
    {
      title: t("name"),
      render: (record) => {
        return record.first_name && record.first_name + " " + record.last_name;
      },
    },
    {
      title: t("email"),
      dataIndex: "email",
    },
    {
      title: t("phone_number"),
      dataIndex: "phone",
    },
    {
      title: t("col_status"),
      dataIndex: "status",
      render: (status) => {
        return status === RESELLER_STATUS_ACTIVE ? (
          <Tag color={"green"}>{t("active")}</Tag>
        ) : status === RESELLER_STATUS_KYC ? (
          <Tag color={"yellow"}>KYC</Tag>
        ) : status === RESELLER_STATUS_REJECTED ? (
          <Tag color={"orange"}>Rejected</Tag>
        ) : status === RESELLER_STATUS_REVIEW ? (
          <Tag color={"blue"}>In Review</Tag>
        ) : status === RESELLER_STATUS_INACTIVE ? (
          <Tag color={"red"}>Inactive</Tag>
        ) : (
          "-"
        );
      },
    },
    {
      title: t("col_action"),
      render: (record) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                navigate("/resellers/" + record.id);
              }}
              title={t("preview")}
              style={{
                border: "1px solid",
                padding: "8px",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 5px",
              }}
            >
              <EyeOutlined twoToneColor="#1890ff" />
            </Button>
            <Button
              type="link"
              onClick={() => handleEditModal(record)}
              title={t("reseller_edit")}
              style={{
                border: "1px solid",
                padding: "8px",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 5px",
              }}
            >
              <EditTwoTone twoToneColor="#1890ff" />
            </Button>
            <Popconfirm
              title={t("warning_delete")}
              onConfirm={() => handledelete(record.id)}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <DeleteTwoTone
                twoToneColor="#ff0000"
                title={t("delete_entity")}
                style={{
                  border: "1px solid #ff0000",
                  padding: "8px",
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "0 5px",
                  borderRadius: "6px",
                }}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const handleSearch = (values) => {
    let search_params = false;

    if (values.status) {
      search_params = {
        status: values.status,
      };
    }

    if (search_params) {
      setSearchParams(search_params);
    } else {
      setSearchParams([]);
    }
  };

  const handleReset = () => {
    // Reset Form Fields
    searchFormRef.resetFields();
    // setPaginationPage(1);
    // Reset List
    setSearchParams([]);
  };

  const searchForm = (
    <>
      <Form
        name="search-reseller-form"
        layout="inline"
        onFinish={handleSearch}
        form={searchFormRef}
      >
        <Form.Item name="status" label={t("status")}>
          <Select
            style={{ width: "200px" }}
            options={resellerStatus.map((type) => ({
              label: type.text,
              value: type.id,
            }))}
          ></Select>
        </Form.Item>

        <Form.Item>
          <Input
            type="submit"
            value={t("search")}
            style={{
              backgroundColor: "#6301f2",
              color: "#ffffff",
              width: "80px",
            }}
          />
        </Form.Item>
        <Form.Item>
          <Input type="button" value={t("reset")} onClick={handleReset} />
        </Form.Item>
      </Form>
    </>
  );

  const handleTransactionsTableChange = (pagination, filters, sorter) => {
    setPaginationData(pagination);
    setCurrentPage(pagination.current);
  };

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb items={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_resellers")}
          ghost={false}
          footer={searchForm}
          extra={[
            <Button
              key="open-add-reseller-modal"
              type="primary"
              onClick={showAddModal}
            >
              + {t("reseller_add")}
            </Button>,
          ]}
        />
      </Card>
      <Card>
        <Table
          columns={tableColumns}
          dataSource={tableData}
          loading={isDataLoading}
          rowKey="id"
          pagination={{
            current: paginationData.current_page,
            pageSize: paginationData.per_page,
            total: paginationData.total,
            showSizeChanger: false,
          }}
          onChange={handleTransactionsTableChange}
        />
      </Card>
      <Modal
        open={modalVisible}
        title={modalTitle}
        onOk={handleAddEdit}
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleAddEdit}
          >
            {modalTitle === ADD_RESELLER || modalTitle === t("reseller_add")
              ? t("reseller_add")
              : t("update")}
          </Button>,
        ]}
      >
        <Form
          name="add-resellers-form"
          layout="vertical"
          onFinish={handleAddEdit}
          style={{ width: "100%", margin: "0" }}
          form={resellerForm}
        >
          <Row gutter={[20, 10]}>
            <Col span={12}>
              <Form.Item
                name="first_name"
                label={t("first_name")}
                rules={[
                  {
                    required: true,
                    message: t("validate_first_name_required"),
                    whitespace: true,
                  },
                  {
                    min: 3,
                    message: t("validate_first_name_min_characters"),
                  },
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="last_name"
                label={t("last_name")}
                rules={[
                  {
                    required: true,
                    message: t("validate_last_name_required"),
                    whitespace: true,
                  },
                  {
                    min: 3,
                    message: t("validate_last_name_min_characters"),
                  },
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="phone"
                label={t("phone_number") + " eg: +966 5xxxxxxx"}
                rules={[
                  {
                    required: true,
                    message: t("validate_phone_required"),
                  },
                  {
                    min: 12,
                    message: t("validate_phone_exact_limit"),
                  },
                  {
                    max: 12,
                    message: t("validate_phone_exact_limit"),
                  },
                ]}
                style={{ textAlign: "left" }}
                hasFeedback
              >
                <PhoneInput
                  localization={["sa"]}
                  masks={{ sa: "(...) ..-..-.." }}
                  placeholder="+966 (123) 45-67-89"
                  country={"sa"}
                  onlyCountries={["sa"]}
                  inputStyle={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="email"
                label={t("email")}
                rules={[
                  {
                    required: true,
                    message: t("validate_email_required"),
                  },
                  {
                    type: "email",
                    message: t("validate_email_invalid"),
                  },
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="password"
                label={t("password")}
                rules={
                  validatePassword && [
                    {
                      required: true,
                      message: t("validate_password_required"),
                    },
                    {
                      min: 6,
                      message: t("validate_password_min_characters"),
                    },
                  ]
                }
                hasFeedback
              >
                <Input.Password
                  onChange={passwordValueChanged}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="password_confirmation"
                label={t("confirm_password")}
                dependencies={["password"]}
                hasFeedback
                rules={
                  validatePassword && [
                    {
                      required: true,
                      message: t("validate_password_confirm_not_matched"),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(t("validate_password_confirm_not_matched"))
                        );
                      },
                    }),
                  ]
                }
              >
                <Input.Password autoComplete="off" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="user_type"
                label={t("type")}
                rules={[
                  {
                    required: true,
                    message: t("validate_type_required"),
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) => {
                    setUserType(e.target.value);
                  }}
                >
                  <Radio value="individual">{t("individual")}</Radio>
                  <Radio value="company">{t("company")}</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            {(userType === "individual" || userType === "company") && (
              <Col span={5}>
                <div className="ant-form-item css-dev-only-do-not-override-3nf8hw">
                  <div className="ant-form-item-label">
                    <label
                      className="ant-form-item-required"
                      title={t("photo_of_id")}
                    >
                      {t("photo_of_id")}
                    </label>
                  </div>
                  <ImageUpload
                    name="image"
                    callbackSetImageFile={setUserIDImage}
                    image={userIDImage}
                  />
                  {!(
                    modalTitle === ADD_RESELLER ||
                    modalTitle === t("reseller_add")
                  ) &&
                    modalData.user_photo_id && (
                      <Button
                        type="primary"
                        href={modalData.user_photo_id}
                        target="_blank"
                      >
                        <EyeOutlined /> {t("preview")}
                      </Button>
                    )}
                </div>
              </Col>
            )}
            {userType === "company" && (
              <>
                <Col span={7}>
                  <div className="ant-form-item css-dev-only-do-not-override-3nf8hw">
                    <div className="ant-form-item-label">
                      <label
                        className="ant-form-item-required"
                        title={t("company_registration_document")}
                      >
                        {t("company_registration_document")}
                      </label>
                    </div>
                    <Upload.Dragger
                      maxCount={1}
                      showUploadList={false}
                      accept=".pdf"
                      beforeUpload={beforeUpload}
                      customRequest={(info) => {
                        setCompanyRegistrationDoc(info.file);
                      }}
                    >
                      {companyRegistrationDoc ? (
                        <div>
                          <UploadOutlined />
                          <div style={{ marginTop: 8 }}>{t("change")}</div>
                        </div>
                      ) : (
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>{t("upload")}</div>
                        </div>
                      )}
                      {companyRegistrationDoc?.name}
                    </Upload.Dragger>
                    {!(
                      modalTitle === ADD_RESELLER ||
                      modalTitle === t("reseller_add")
                    ) &&
                      modalData.company_registration_document && (
                        <Button
                          type="primary"
                          href={modalData.company_registration_document}
                          target="_blank"
                          style={{ marginTop: "10px" }}
                        >
                          <EyeOutlined /> {t("preview")}
                        </Button>
                      )}
                  </div>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="reseller_company_name"
                    label={t("company_name")}
                    rules={[
                      {
                        required: true,
                        message: t("validate_company_name_required"),
                        whitespace: true,
                      },
                    ]}
                    hasFeedback
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </>
            )}
            <Col span={6}>
              <Form.Item name="id" style={{ display: "none" }}>
                <input type="hidden" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default Reseller;
