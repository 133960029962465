import React, { useContext } from "react";
import { Card, Row, Col, Table } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  HomeOutlined,
  EyeOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { currencyFormatter } from "../../../utils";
import moment from "moment";
import { AppContext } from "../../../context/AppContextProvider";

const PayoutDetails = () => {
  const { setActiveMenu } = useContext(AppContext);
  const navigate = useNavigate();
  // const [isDataLoading, setIsDataLoading] = useState(false);

  const { t } = useTranslation();

  // useEffect(() => {
  //   setIsDataLoading(true);
  //   getReseller(resellerId)
  //     .then((response) => {
  //       setTableData(response.data.data.reseller);
  //     })
  //     .finally(() => {
  //       setIsDataLoading(false);
  //     });
  // }, [reloadTableData, resellerId]);

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "/resellers",
      breadcrumbName: t("menu_resellers"),
      icon: <UsergroupAddOutlined />,
    },
    {
      path: "",
      breadcrumbName: t("menu_payout_detail"),
      icon: <EyeOutlined />,
    },
  ];

  const tableColumns = [
    {
      title: "#",
      key: "index",
      width: "50px",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("business_name"),
      render: (record) => {
        return (
          <>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setActiveMenu("companies");
                navigate({
                  pathname: "/companies/" + record.company_id,
                });
              }}
            >
              {record.company_name}
            </span>
          </>
        );
      },
    },
    {
      title: t("amount"),
      render: (payout) => {
        return <>{currencyFormatter.format(payout.amount)}</>;
      },
    },
  ];

  const tableData = {
    account_number: "123456789",
    total_amount: 10,
    date: "2024-06-04 00:00:00",
    reference_number: "abcd1234",
    payout_detail_history: [
      {
        id: 1,
        amount: "2",
        company_name: "ABC",
        company_id: 1,
      },
      {
        id: 2,
        amount: "3",
        company_name: "ABC",
        company_id: 1,
      },
      {
        id: 3,
        amount: "4",
        company_name: "ABC",
        company_id: 1,
      },
      {
        id: 4,
        amount: "1",
        company_name: "ABC",
        company_id: 1,
      },
    ],
  };

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb items={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_payout_detail")}
          onBack={() => window.history.back()}
          ghost={false}
          extra={[]}
        />
      </Card>
      {/* <Spin spinning={isDataLoading}> */}
      <Card style={{ marginBottom: "20px" }}>
        <Row gutter={[20, 20]}>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={6}
            xxl={6}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("account_number")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {tableData.account_number}
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={6}
            xxl={6}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("total_amount")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {currencyFormatter.format(tableData.total_amount)}
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={6}
            xxl={6}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("reference_no")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {tableData.reference_number}
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={6}
            xxl={6}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("date")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {moment(tableData.date).format("MMM. D, YYYY")}
            </div>
          </Col>
        </Row>
      </Card>
      <Table
        columns={tableColumns}
        dataSource={tableData.payout_detail_history}
        rowKey="id"
        pagination={false}
      />
      {/* </Spin> */}
    </>
  );
};

export default PayoutDetails;
