import React, { useState } from "react";
import { Button, Col, Empty, Form, Input, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { resellerBankAdding } from "../../../network/network";

const BankDetails = ({ data, resellerId, reloadCallback }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [bankDetailsForm] = Form.useForm();

  const showModal = () => {
    // Show Modal
    setModalVisible(true);
    // Reset Form Fields
    bankDetailsForm.resetFields();
    // Set Form Fields
    bankDetailsForm.setFieldsValue({
      country: "Saudi Arabia",
    });
  };

  const handleCancel = () => {
    // Reset Form Fields
    bankDetailsForm.resetFields();
    // close modal
    setModalVisible(false);
  };

  const handleAddEdit = () => {
    bankDetailsForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);
        // Sending Request to API
        resellerBankAdding(resellerId, values)
          .then((response) => {
            let data = response.data || response.response.data;
            if (data.success === true) {
              // Success
              NotificationWithIcon(
                "success",
                t("bank_detail_added"),
                t("bank_detail_added_successfully")
              );
              reloadCallback();
              bankDetailsForm.resetFields();
              setModalVisible(false);
            } else {
              let errors = data?.data?.errors;
              if (errors && Object.keys(errors).length) {
                // Error
                NotificationWithIcon(
                  "error",
                  t("something_went_wrong"),
                  errors[Object.keys(errors)[0]]
                );
              }
            }
          })
          .catch((info) => {
            console.log("API Failed:", info.response);
            // Error
            NotificationWithIcon(
              "error",
              t("something_went_wrong"),
              info.response.data.data.errors.name
            );
          })
          .finally(() => {
            // Set Loading False
            setLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <div style={{ marginBottom: "20px", textAlign: "right" }}>
        <Button type="primary" onClick={showModal}>
          <PlusOutlined title={t("bank_detail_add")} /> {t("bank_detail_add")}
        </Button>
      </div>
      {data ? (
        <Row gutter={[20, 20]}>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("account_title")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {data.account_title ? data.account_title : "-"}
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("bank_name")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {data.bank_name ? data.bank_name : "-"}
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("account_number")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {data.account_number ? data.account_number : "-"}
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("branch_code")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {data.branch_code ? data.branch_code : "-"}
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("city")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {data.city ? data.city : "-"}
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("country")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {data.country ? data.country : "-"}
            </div>
          </Col>
        </Row>
      ) : (
        <Empty />
      )}
      {/* add or update bank details */}
      <Modal
        open={modalVisible}
        title={t("bank_detail_add")}
        onOk={handleAddEdit}
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleAddEdit}
            loading={loading}
          >
            {t("bank_detail_add")}
          </Button>,
        ]}
      >
        <Form
          name="bank-form"
          layout="vertical"
          onFinish={handleAddEdit}
          style={{ width: "100%", margin: "0" }}
          form={bankDetailsForm}
        >
          <Row gutter={[20, 10]}>
            <Col span={12}>
              <Form.Item
                name="account_title"
                label={t("account_title")}
                rules={[
                  {
                    required: true,
                    message: t("validate_account_title_required"),
                  },
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="bank_name"
                label={t("bank_name")}
                rules={[
                  {
                    required: true,
                    message: t("validate_bank_name_required"),
                  },
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="account_number"
                label={t("account_number")}
                rules={[
                  {
                    required: true,
                    message: t("validate_account_number_required"),
                  },
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="branch_code"
                label={t("branch_code")}
                rules={[
                  {
                    required: true,
                    message: t("validate_branch_code_required"),
                  },
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="city"
                label={t("city")}
                rules={[
                  {
                    required: true,
                    message: t("validate_city_required"),
                  },
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="country"
                label={t("country")}
                rules={[
                  {
                    required: true,
                    message: t("validate_country_required"),
                  },
                ]}
                hasFeedback
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default BankDetails;
