import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Select,
  Input,
  Button,
  Table,
  Modal,
  Popconfirm,
  Radio,
  Tag,
  Row,
  Col,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  HomeOutlined,
  TeamOutlined,
  EditTwoTone,
  DeleteTwoTone,
} from "@ant-design/icons";
import {
  getStaffs,
  getRegions,
  createStaff,
  updateStaff,
  deleteStaff,
  getCities,
} from "../../../network/network";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Staffs = () => {
  const ADD_STAFF = "Add Staff";
  const [modalTitle, setModalTitle] = useState(ADD_STAFF);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reloadTableData, setReloadTableData] = useState(false);
  const [validatePassword, setValidatePassword] = useState(true);
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [staffsTableData, setStaffsTableData] = useState([]);
  const [staffForm] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    setIsDataLoading(true);
    getStaffs()
      .then((response) => {
        setStaffsTableData(response.data.data.staff);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, [reloadTableData]);

  useEffect(() => {
    getRegions()
      .then((response) => {
        setRegions(response.data.data.regions);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  }, []);

  const showAddModal = () => {
    // Set Modal Title
    setModalTitle(t("staff_add"));

    // Reset Form Fields
    staffForm.resetFields();

    // Show Modal
    setModalVisible(true);

    // Enable Password Validation
    setValidatePassword(true);

    // Set Form Fields
    staffForm.setFieldsValue({
      is_active: true,
      is_support_agent: false,
      can_manage_all_regions: false,
    });
  };

  const handleAPIResponse = (data, type) => {
    if (data.success === true) {
      setReloadTableData(!reloadTableData);
      // Success
      if (type === ADD_STAFF) {
        NotificationWithIcon(
          "success",
          t("staff_added"),
          t("staff_added_successfully")
        );
      } else {
        NotificationWithIcon(
          "success",
          t("staff_updated"),
          t("staff_updated_successfully")
        );
      }
    } else {
      // Error
      NotificationWithIcon("error", t("something_went_wrong"), data.message);
    }
    // Reset Form Fields
    staffForm.resetFields();
    // Hide Modal
    setModalVisible(false);
  };

  const handleAddEditStaff = () => {
    staffForm
      .validateFields()

      .then((values) => {
        console.log(values);
        // Set Loading True
        setLoading(true);

        // Sending Request to API
        if (modalTitle === ADD_STAFF || modalTitle === t("staff_add")) {
          createStaff(values)
            .then((response) => {
              let data = response.data || response.response.data;
              if (data.success === true) {
                return handleAPIResponse(data, ADD_STAFF);
              } else {
                let errors = data?.data?.errors;
                if (errors && Object.keys(errors).length) {
                  // Error
                  NotificationWithIcon(
                    "error",
                    t("something_went_wrong"),
                    errors[Object.keys(errors)[0]]
                  );
                }
              }
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        } else {
          updateStaff(values)
            .then((response) => {
              let data = response.data || response.response.data;
              if (data.success === true) {
                return handleAPIResponse(data, null);
              } else {
                let errors = data?.data?.errors;
                if (errors && Object.keys(errors).length) {
                  // Error
                  NotificationWithIcon(
                    "error",
                    t("something_went_wrong"),
                    errors[Object.keys(errors)[0]]
                  );
                }
              }
            })
            .finally(() => {
              // Set Loading False
              setLoading(false);
            });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleEditModal = (staff_id) => {
    staffForm.resetFields();
    // Find staff from DataArray
    const staff = staffsTableData.find((emp) => emp.id === staff_id);

    // Set Modal Title
    setModalTitle(t("staff_edit") + " : " + staff.name);

    // Show Modal
    setModalVisible(true);

    // Set Form Fields
    staffForm.setFieldsValue({
      id: staff.id,
      first_name: staff.first_name,
      last_name: staff.last_name,
      phone: staff.phone,
      email: staff.email,
      region_id: staff.region?.id,
      cities: staff.cities.map(({ id }) => id),
      is_active: staff.is_active === 1 ? true : false,
      is_support_agent: staff.is_support_agent === 1 ? true : false,
      can_manage_all_regions: staff.can_manage_all_regions === 1 ? true : false,
    });
    // handel citites request by region in edit modal
    handleCityRequest(staff.region?.id);
    // Disable Password Validation
    setValidatePassword(false);
  };

  const handleCancel = () => {
    // Reset Form Fields
    staffForm.resetFields();
    setModalVisible(false);
  };

  const handleDeleteStaff = (id) => {
    deleteStaff(id)
      .then((response) => {
        if (response.data.success === true) {
          setReloadTableData(!reloadTableData);
          // Success
          NotificationWithIcon(
            "success",
            t("staff_deleted"),
            t("staff_deleted_successfully")
          );
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            response.data.message
          );
        }
      })
      .catch((info) => {
        // Error
        console.log("Delete error: ", info.response.data.message);
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          info.response.data.message
        );
      });
  };

  const passwordValueChanged = (e) => {
    if (
      e.target.value.length === 0 &&
      !(modalTitle === ADD_STAFF || modalTitle === t("staff_add"))
    ) {
      setValidatePassword(false);
    } else {
      setValidatePassword(true);
    }
  };

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "staffs",
      breadcrumbName: t("menu_staffs"),
      icon: <TeamOutlined />,
    },
  ];

  const StaffsTableColumns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => {
        return <>{index + 1}</>;
      },
    },
    {
      title: t("name"),
      dataIndex: "name",
    },
    // {
    //   title: t("email"),
    //   dataIndex: "email",
    // },
    {
      title: t("phone_number"),
      dataIndex: "phone",
    },
    {
      title: t("col_region"),
      dataIndex: "region",
      render: (region) => {
        return region?.name_en;
      },
    },
    {
      title: t("col_cities"),
      dataIndex: "cities",
      render: (cities) => {
        return cities.map((city, i) => {
          return (
            <Tag color="blue" key={i}>
              {city?.name_en}
            </Tag>
          );
        });
      },
    },
    {
      title: t("col_status"),
      dataIndex: "is_active",
      render: (is_active) => {
        return is_active ? (
          <Tag color="green">{t("active")}</Tag>
        ) : (
          <Tag color="red">{t("inactive")}</Tag>
        );
      },
    },
    {
      title: t("is_support_agent"),
      dataIndex: "is_support_agent",
      render: (is_support_agent) => {
        return is_support_agent ? (
          <Tag color="green">{t("yes")}</Tag>
        ) : (
          <Tag color="red">{t("no")}</Tag>
        );
      },
    },
    {
      title: t("can_manage_all_regions"),
      dataIndex: "can_manage_all_regions",
      render: (can_manage_all_regions) => {
        return can_manage_all_regions ? (
          <Tag color="green">{t("yes")}</Tag>
        ) : (
          <Tag color="red">{t("no")}</Tag>
        );
      },
    },
    {
      title: t("col_action"),
      render: (record) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => handleEditModal(record.id)}
              title={t("staff_edit")}
            >
              <EditTwoTone twoToneColor="#1890ff" />
            </Button>
            <Popconfirm
              title={t("warning_delete")}
              onConfirm={() => handleDeleteStaff(record.id)}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <DeleteTwoTone
                twoToneColor="#ff0000"
                title={t("delete_entity")}
              />
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const handleCityRequest = (value) => {
    if (value) {
      getCities(value).then((response) => {
        setCities(response.data.data.cities);
      });
    }
  };

  const handleRegionSelect = (value) => {
    setIsDataLoading(true);
    staffForm.resetFields(["cities"]);
    getCities(value)
      .then((response) => {
        setCities(response.data.data.cities);
      })
      .finally(() => {
        setIsDataLoading(false);
      });
  };

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb items={breadcrumbRoutes} />
        <PageHeader
          style={{ padding: "0" }}
          title={t("menu_staffs")}
          ghost={false}
          extra={[
            <Button
              key="open-add-staff-modal"
              type="primary"
              onClick={showAddModal}
            >
              + {t("staff_add")}
            </Button>,
          ]}
        />
      </Card>
      <Card>
        <Table
          columns={StaffsTableColumns}
          dataSource={staffsTableData}
          loading={isDataLoading}
          rowKey="id"
          pagination={false}
        />
      </Card>
      <Modal
        open={modalVisible}
        title={modalTitle}
        onOk={handleAddEditStaff}
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleAddEditStaff}
          >
            {modalTitle === ADD_STAFF || modalTitle === t("staff_add")
              ? t("staff_add")
              : t("update")}
          </Button>,
        ]}
      >
        <Form
          name="add-staff-form"
          layout="vertical"
          onFinish={handleAddEditStaff}
          style={{ width: "100%", margin: "0" }}
          form={staffForm}
        >
          <Row gutter={[20, 10]}>
            <Col span={12}>
              <Form.Item
                name="first_name"
                label={t("first_name")}
                rules={[
                  {
                    required: true,
                    message: t("validate_first_name_required"),
                    whitespace: true,
                  },
                  {
                    min: 3,
                    message: t("validate_first_name_min_characters"),
                  },
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="last_name"
                label={t("last_name")}
                rules={[
                  {
                    required: true,
                    message: t("validate_last_name_required"),
                    whitespace: true,
                  },
                  {
                    min: 3,
                    message: t("validate_last_name_min_characters"),
                  },
                ]}
                hasFeedback
              >
                <Input autoComplete="off" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="phone"
                label={t("phone_number") + " eg: +966 5xxxxxxx"}
                rules={[
                  {
                    required: true,
                    message: t("validate_phone_required"),
                  },
                  {
                    min: 12,
                    message: t("validate_phone_exact_limit"),
                  },
                  {
                    max: 12,
                    message: t("validate_phone_exact_limit"),
                  },
                ]}
                style={{ textAlign: "left" }}
                hasFeedback
              >
                <PhoneInput
                  localization={["sa"]}
                  masks={{ sa: "(...) ..-..-.." }}
                  placeholder="+966 (123) 45-67-89"
                  country={"sa"}
                  onlyCountries={["sa"]}
                  inputStyle={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="email"
                label={t("email")}
                rules={[
                  {
                    type: "email",
                    message: t("validate_email_invalid"),
                  },
                ]}
                hasFeedback
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="password"
                label={t("password")}
                rules={
                  validatePassword && [
                    {
                      required: true,
                      message: t("validate_password_required"),
                    },
                    {
                      min: 6,
                      message: t("validate_password_min_characters"),
                    },
                  ]
                }
                hasFeedback
              >
                <Input.Password
                  onChange={passwordValueChanged}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="password_confirmation"
                label={t("confirm_password")}
                dependencies={["password"]}
                hasFeedback
                rules={
                  validatePassword && [
                    {
                      required: true,
                      message: t("validate_password_confirm_not_matched"),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(t("validate_password_confirm_not_matched"))
                        );
                      },
                    }),
                  ]
                }
              >
                <Input.Password autoComplete="off" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="region_id"
                label={t("col_region")}
                rules={[
                  {
                    required: true,
                    message: t("validate_region_required"),
                  },
                ]}
                hasFeedback
              >
                <Select
                  showSearch
                  onChange={handleRegionSelect}
                  options={regions.map((region) => {
                    return {
                      label: region.name_en,
                      value: region.id,
                    };
                  })}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="cities"
                label={t("col_cities")}
                rules={[
                  {
                    required: true,
                    message: t("validate_city_required"),
                  },
                ]}
                hasFeedback
              >
                <Select
                  showSearch
                  mode="multiple"
                  allowClear
                  options={cities.map((city) => {
                    return {
                      label: city.name_en,
                      value: city.id,
                    };
                  })}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="is_active"
                label={t("status")}
                rules={[
                  {
                    required: true,
                    message: t("validate_status_required"),
                  },
                ]}
              >
                <Radio.Group value={true} buttonStyle="solid" key="is-active">
                  <Radio.Button key="is-active-false" value={false}>
                    {t("inactive")}
                  </Radio.Button>
                  <Radio.Button key="is-active-true" value={true}>
                    {t("active")}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="is_support_agent"
                label={t("is_support_agent")}
                rules={[
                  {
                    required: true,
                    message: t("validate_support_agent_required"),
                  },
                ]}
              >
                <Radio.Group
                  value={true}
                  buttonStyle="solid"
                  key="is-support-agent"
                >
                  <Radio.Button key="is-support-agent-false" value={false}>
                    {t("no")}
                  </Radio.Button>
                  <Radio.Button key="is-support-agent-true" value={true}>
                    {t("yes")}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="can_manage_all_regions"
                label={t("can_manage_all_regions")}
                rules={[
                  {
                    required: true,
                    message: t("validate_manage_all_regions_required"),
                  },
                ]}
              >
                <Radio.Group
                  value={true}
                  buttonStyle="solid"
                  key="can-manage-all-regions"
                >
                  <Radio.Button
                    key="can-manage-all-regions-false"
                    value={false}
                  >
                    {t("no")}
                  </Radio.Button>
                  <Radio.Button key="can-manage-all-regions-true" value={true}>
                    {t("yes")}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="id" style={{ display: "none" }}>
                <input type="hidden" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default Staffs;
