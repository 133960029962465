import React, { useEffect, useState } from "react";
import { Form, Button, Select, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { updateCompanyReseller, getResellers } from "../../../network/network";
import NotificationWithIcon from "../../../components/NotificationWithIcon";

const UpdateReseller = ({ company, reloadCallback }) => {
  const [updateModal, setUpdateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resellers, setResellers] = useState([]);
  const { t } = useTranslation();
  const [UpdateResellerForm] = Form.useForm();

  useEffect(() => {
    setLoading(true);
    getResellers()
      .then((response) => {
        setResellers(response.data.data.resellers);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const showEditModel = () => {
    // Set Form Fields
    UpdateResellerForm.setFieldsValue({
      company_id: company.id,
      reseller_number: company?.reseller?.reseller_number,
    });
    setUpdateModal(true);
  };

  const handleUpdateReseller = () => {
    UpdateResellerForm.validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);
        // Sending Request to API
        updateCompanyReseller(values)
          .then((response) => {
            if (response.data.success === true) {
              // Success
              NotificationWithIcon(
                "success",
                t("reseller_updated"),
                t("reseller_updated_successfully")
              );
              reloadCallback();
            } else {
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                response.data.message
              );
            }
            // Hide Modal
            setUpdateModal(false);
          })
          .catch((info) => {
            console.log("API Failed:", info.response);
            // Error
            NotificationWithIcon(
              "error",
              t("something_went_wrong"),
              info.response.data.data.errors.name
            );
          })
          .finally(() => {
            // Set Loading False
            setLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <Button
        type="primary"
        onClick={showEditModel}
        style={{ marginRight: "20px" }}
      >
        {t("update_reseller")}
      </Button>

      {/* update company reseller */}
      <Modal
        title={t("update_reseller")}
        open={updateModal}
        onCancel={() => {
          setUpdateModal(false);
        }}
        onOk={handleUpdateReseller}
        footer={[
          <Button
            key="back"
            loading={loading}
            onClick={() => setUpdateModal(false)}
          >
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleUpdateReseller}
          >
            {t("update")}
          </Button>,
        ]}
      >
        <Form
          name="update-Reseller-form"
          layout="vertical"
          onFinish={handleUpdateReseller}
          style={{ width: "100%" }}
          form={UpdateResellerForm}
        >
          <Form.Item
            name="reseller_number"
            label={t("select_company_reseller")}
            rules={[
              {
                required: true,
                message: t("validate_company_reseller_required"),
              },
            ]}
            hasFeedback
          >
            <Select
              showSearch
              allowClear
              options={resellers.map((reseller) => {
                return {
                  label: reseller.first_name + " " + reseller.last_name,
                  value: reseller.reseller_number,
                };
              })}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
          <Form.Item name="company_id" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UpdateReseller;
