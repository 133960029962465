import React, { useState } from "react";
import { Form, Button, Modal, Space, Input } from "antd";
import { useTranslation } from "react-i18next";
import {
  changeResellerStatus,
  downgradeReseller,
  upgradeReseller,
} from "../../../network/network";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import {
  RANK_BASIC,
  RESELLER_STATUS_ACTIVE,
  RESELLER_STATUS_INACTIVE,
  RESELLER_STATUS_KYC,
  RESELLER_STATUS_REVIEW,
} from "../../../configs/constants";

const ResellerActions = ({ reseller, reloadCallback }) => {
  const [updateModal, setUpdateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [rejectionForm] = Form.useForm();

  const showEditModel = () => {
    // Set Form Fields
    rejectionForm.setFieldsValue({
      status: 3,
    });
    setUpdateModal(true);
  };

  // this function is used in active or inactive case
  const handlechangeStatus = (status) => {
    // Set Loading True
    setLoading(true);
    const formData = new FormData();
    formData.append("status", status);
    changeResellerStatus(reseller.id, formData)
      .then((response) => {
        let data = response.data || response.response.data;
        console.log(data);
        if (data.success === true) {
          NotificationWithIcon(
            "success",
            t("status_updated_successfully"),
            data.message
          );
          reloadCallback();
        } else {
          // Error
          NotificationWithIcon(
            "error",
            t("something_went_wrong"),
            data.message
          );
        }
      })
      .catch((info) => {
        console.log("API Failed:", info.response);
        // Error
        NotificationWithIcon(
          "error",
          t("something_went_wrong"),
          info.response.data.data.errors.name
        );
      })
      .finally(() => {
        // Set Loading False
        setLoading(false);
      });
  };

  // this function is used only in reject case
  const handleUpdateStatus = () => {
    rejectionForm
      .validateFields()
      .then((values) => {
        // Set Loading True
        setLoading(true);
        // Sending Request to API
        changeResellerStatus(reseller.id, values)
          .then((response) => {
            let data = response.data || response.response.data;
            if (data.success === true) {
              NotificationWithIcon(
                "success",
                t("status_updated_successfully"),
                data.message
              );
              reloadCallback();
            } else {
              // Error
              NotificationWithIcon(
                "error",
                t("something_went_wrong"),
                data.message
              );
            }
            // Hide Modal
            setUpdateModal(false);
          })
          .catch((info) => {
            console.log("API Failed:", info.response);
            // Error
            NotificationWithIcon(
              "error",
              t("something_went_wrong"),
              info.response.data.data.errors.name
            );
          })
          .finally(() => {
            // Set Loading False
            setLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleUpgrade = () => {
    upgradeReseller(reseller.id).then((response) => {
      let data = response.data || response.response.data;
      if (data.success === true) {
        NotificationWithIcon(
          "success",
          t("reseller_upgrade"),
          t("reseller_upgrade_successfully")
        );
        reloadCallback();
      } else {
        // Error
        NotificationWithIcon("error", t("something_went_wrong"), data.message);
      }
    });
  };

  const handleDowngrade = () => {
    downgradeReseller(reseller.id).then((response) => {
      let data = response.data || response.response.data;
      if (data.success === true) {
        NotificationWithIcon(
          "success",
          t("reseller_downgrade"),
          t("reseller_downgrade_successfully")
        );
        reloadCallback();
      } else {
        // Error
        NotificationWithIcon("error", t("something_went_wrong"), data.message);
      }
    });
  };

  return (
    <>
      <Space wrap size={"small"}>
        {reseller.status !== RESELLER_STATUS_ACTIVE && (
          <Button
            type="button"
            style={{
              backgroundColor: "green",
              color: "#ffffff",
            }}
            onClick={() => handlechangeStatus(1)}
          >
            Activate
          </Button>
        )}
        {(reseller.status === RESELLER_STATUS_KYC ||
          reseller.status === RESELLER_STATUS_REVIEW) && (
          <Button
            type="button"
            style={{
              backgroundColor: "orange",
              color: "#ffffff",
            }}
            onClick={showEditModel}
          >
            Reject
          </Button>
        )}
        {reseller.status !== RESELLER_STATUS_INACTIVE && (
          <Button
            type="button"
            style={{
              backgroundColor: "red",
              color: "#ffffff",
            }}
            onClick={() => handlechangeStatus(99)}
          >
            Inactive
          </Button>
        )}
        {reseller.status === RESELLER_STATUS_ACTIVE &&
          (reseller.reseller_level === RANK_BASIC ? (
            <Button key="upgrade" type="primary" onClick={handleUpgrade}>
              {t("upgrade_reseller")}
            </Button>
          ) : (
            <Button key="downgrade" type="primary" onClick={handleDowngrade}>
              {t("downgrade_reseller")}
            </Button>
          ))}
      </Space>

      <Modal
        title={t("reject_reason")}
        open={updateModal}
        onCancel={() => {
          setUpdateModal(false);
        }}
        onOk={handleUpdateStatus}
        footer={[
          <Button
            key="back"
            loading={loading}
            onClick={() => setUpdateModal(false)}
          >
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleUpdateStatus}
          >
            {t("submit")}
          </Button>,
        ]}
      >
        <Form
          name="add-rejection-reason"
          layout="vertical"
          onFinish={handleUpdateStatus}
          style={{ width: "100%" }}
          form={rejectionForm}
        >
          <Form.Item
            name="rejection_reason"
            rules={[
              {
                required: true,
                message: t("validate_rejection_reason_required"),
              },
            ]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          <Form.Item name="status" style={{ display: "none" }}>
            <input type="hidden" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ResellerActions;
