import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./pages/App/App";
import AppContextProvider from "./context/AppContextProvider";
import { BrowserRouter } from "react-router-dom";
import "./i18n";

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;
window.Echo = new Echo({
    broadcaster: 'reverb',
    key: process.env.REACT_APP_REVERB_APP_KEY,
    cluster: process.env.REACT_APP_REVERB_APP_CLUSTER ?? 'mt1',
    wsHost: process.env.REACT_APP_REVERB_HOST ? process.env.REACT_APP_REVERB_HOST : `ws-${process.env.REACT_APP_REVERB_APP_CLUSTER}.pusher.com`,
    wsPort: process.env.REACT_APP_REVERB_PORT ?? 80,
    wssPort: process.env.REACT_APP_REVERB_WSS_PORT ?? 443,
    forceTLS: (process.env.REACT_APP_REVERB_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
    disableStats: true,
    authEndpoint: process.env.REACT_APP_API_ROOT + "/api/broadcasting/auth",
});

if (localStorage.getItem("access_token")) {
    window.Echo.connector.options.auth.headers['Authorization'] = 'Bearer ' + localStorage.getItem("access_token");
    window.Echo.connector.options.auth.headers['Accept'] = "application/json";
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <BrowserRouter>
        <AppContextProvider>
          <App />
        </AppContextProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
