import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getCompany } from "../../../network/network";
import { Card, Button, Tabs, Tag } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import {
  HomeOutlined,
  AppstoreOutlined,
  RightOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import CustomBreadcrumb from "../../../components/CustomBreadcrumb";
import { useTranslation } from "react-i18next";
import BranchesTableWithEditModal from "../CompanyDetails/BranchesTableWithEditModal";
import EmployeesTableWithEditModal from "../CompanyDetails/EmployeesTableWithEditModal";
import DevicesTableWithAddModal from "../CompanyDetails/DevicesTableWithAddModal";
import InvoicesTable from "../CompanyDetails/InvoicesTable";
import CompanyGeneralInfo from "../CompanyDetails/CompanyGeneralInfo";
import CustomerInfo from "../CompanyDetails/CustomerInfo";
import { useNavigate, useLocation } from "react-router-dom";
import CompanySubscriptions from "../CompanyDetails/CompanySubscriptions";

const Company = () => {
  const [reload, setReload] = useState(false);
  const [company, setCompany] = useState(false);
  const [nextCompanyId, setNextCompanyId] = useState(false);
  const [previousCompanyId, setPreviousCompanyId] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const { t } = useTranslation();
  let { companyId } = useParams();
  const [CID, setCID] = useState(companyId);
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;
  const [searchParams, setSearchParams] = useState(search);

  useEffect(() => {
    setSearchParams(searchParams);
    let formData = searchParams ? CID + searchParams : CID;

    getCompany(formData).then((response) => {
      setCompany(response.data.data.company);
      setNextCompanyId(response.data.data.next_company_id);
      setPreviousCompanyId(response.data.data.prev_company_id);
    });
  }, [CID, reload, searchParams, navigate]);

  useEffect(() => {
    setIsDisabled(!company.is_active);
  }, [company]);

  const breadcrumbRoutes = [
    {
      path: "/",
      breadcrumbName: t("menu_home"),
      icon: <HomeOutlined />,
    },
    {
      path: "/companies",
      breadcrumbName: t("menu_companies"),
      icon: <AppstoreOutlined />,
    },
    {
      path: "",
      breadcrumbName: company ? company.name : "",
      icon: false,
    },
  ];

  const tabItems = [
    {
      label: "General",
      key: "tab-general",
      children: <CompanyGeneralInfo />,
    },
    {
      label: "Customer info",
      key: "tab-customer-info",
      children: (
        <CustomerInfo
          company={company}
          reloadCallback={() => setReload(!reload)}
        />
      ),
    },
    {
      label: "Addons",
      key: "tab-addons",
      children: company.active_addons?.map((addon) => (
        <div
          key={addon.id} // Assuming each addon has a unique 'id' property
          style={{
            boxShadow: "rgba(154, 154, 154, 0.537) 0 0 20px -5px",
            margin: "10px",
            borderRadius: "10px",
            padding: "10px",
            display: "inline-block",
          }}
        >
          {addon.name}
        </div>
      )),
    },
    {
      label: "Branches",
      key: "tab-branches",
      children: (
        <BranchesTableWithEditModal
          company={company}
          branches={company.branches}
          reloadCallback={() => setReload(!reload)}
          isDisabled={isDisabled}
        />
      ),
    },
    {
      label: "Users",
      key: "tab-users",
      children: <EmployeesTableWithEditModal employees={company.employees} />,
    },
    {
      label: "Subscriptions",
      key: "tab-subscriptions",
      children: (
        <CompanySubscriptions
          subscriptions={company.subscriptions}
          company={company}
          reloadCallback={() => setReload(!reload)}
          isDisabled={isDisabled}
        />
      ),
    },
    {
      label: "Invoices",
      key: "tab-invoices",
      children: (
        <InvoicesTable
          company={company}
          reloadCallback={() => setReload(!reload)}
          isDisabled={isDisabled}
        />
      ),
    },
    {
      label: "Devices",
      key: "tab-devices",
      children: (
        <DevicesTableWithAddModal
          companyId={company.id}
          reloadCallback={() => setReload(!reload)}
          isDisabled={isDisabled}
        />
      ),
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: "20px" }}>
        <CustomBreadcrumb items={breadcrumbRoutes} />
        {company && (
          <>
            <PageHeader
              style={{ padding: "0" }}
              title={t("company") + " : " + company.name}
              onBack={() => window.history.back()}
              avatar={{
                src: company.logo
                  ? company.logo
                  : "/images/image-not-found.png",
              }}
              tags={
                <Tag
                  color={
                    company.last_active_at === "Unknown"
                      ? "red"
                      : company.last_active_at === "Today"
                      ? "green"
                      : "blue"
                  }
                >
                  Last Active: {company.last_active_at}
                </Tag>
              }
              ghost={false}
              extra={[
                <Button
                  key="nex-company"
                  title="Next"
                  onClick={() => {
                    setCID(nextCompanyId);
                    navigate({
                      pathname: "/companies/" + nextCompanyId,
                      search: searchParams.toString(),
                    });
                  }}
                  disabled={nextCompanyId ? false : true}
                >
                  <LeftOutlined />
                </Button>,
                <Button
                  key="prev-company"
                  title="Previous"
                  onClick={() => {
                    setCID(previousCompanyId);
                    navigate({
                      pathname: "/companies/" + previousCompanyId,
                      search: searchParams.toString(),
                    });
                  }}
                  disabled={previousCompanyId ? false : true}
                >
                  <RightOutlined />
                </Button>,
              ]}
            />
          </>
        )}
      </Card>
      <Card>
        <Tabs defaultActiveKey="tab-general" items={tabItems} />
      </Card>
    </>
  );
};

export default Company;
