import React, { useState } from "react";
import { Button, Col, Empty, Form, InputNumber, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import NotificationWithIcon from "../../../components/NotificationWithIcon";
import { resellerConfigAdding } from "../../../network/network";

const LevelConfiguration = ({ data, resellerId, reloadCallback }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [LevelConfigurationForm] = Form.useForm();

  const showModal = () => {
    // Show Modal
    setModalVisible(true);
    // Reset Form Fields
    LevelConfigurationForm.resetFields();
    // Set Form Fields
    LevelConfigurationForm.setFieldsValue({
      basic_target: 0,
      basic_retain_rate: 0,
    });
  };

  const handleCancel = () => {
    // Reset Form Fields
    LevelConfigurationForm.resetFields();
    // close modal
    setModalVisible(false);
  };

  const handleAddEdit = () => {
    LevelConfigurationForm.validateFields()
      .then((values) => {
        // Set Loading True
        console.log(values);
        setLoading(true);
        // Sending Request to API
        resellerConfigAdding(resellerId, values)
          .then((response) => {
            let data = response.data || response.response.data;
            if (data.success === true) {
              // Success
              NotificationWithIcon(
                "success",
                t("config_added"),
                t("config_added_successfully")
              );
              reloadCallback();
              LevelConfigurationForm.resetFields();
              setModalVisible(false);
            } else {
              let errors = data?.data?.errors;
              if (errors && Object.keys(errors).length) {
                // Error
                NotificationWithIcon(
                  "error",
                  t("something_went_wrong"),
                  errors[Object.keys(errors)[0]]
                );
              }
            }
          })
          .catch((info) => {
            console.log("API Failed:", info.response);
            // Error
            NotificationWithIcon(
              "error",
              t("something_went_wrong"),
              info.response.data.data.errors.name
            );
          })
          .finally(() => {
            // Set Loading False
            setLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <div style={{ marginBottom: "20px", textAlign: "right" }}>
        <Button type="primary" onClick={showModal}>
          <PlusOutlined title={t("config_add")} /> {t("config_add")}
        </Button>
      </div>
      {data ? (
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <h3>{t("basic_level")}</h3>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("target")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {data.basic_target ? data.basic_target : "-"}
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("retain_rate")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {data.basic_retain_rate ? data.basic_retain_rate + "%" : "-"}
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("commission")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {data.basic_commission ? data.basic_commission + "%" : "-"}
            </div>
          </Col>
          <Col span={24}>
            <h3>{t("pro_level")}</h3>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("target")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {data.pro_target ? data.pro_target : "-"}
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("retain_rate")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {data.pro_retain_rate ? data.pro_retain_rate + "%" : "-"}
            </div>
          </Col>
          <Col
            className="gutter-row"
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={8}
            xxl={8}
          >
            <div
              style={{
                fontWeight: "bold",
                paddingBottom: "10px",
              }}
            >
              {t("commission")}
            </div>
            <div style={{ border: "1px solid #d9d9d9", padding: "10px" }}>
              {data.pro_commission ? data.pro_commission + "%" : "-"}
            </div>
          </Col>
        </Row>
      ) : (
        <Empty />
      )}
      {/* add or update target details */}
      <Modal
        open={modalVisible}
        title={t("config_add")}
        onOk={handleAddEdit}
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t("cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleAddEdit}
            loading={loading}
          >
            {t("config_add")}
          </Button>,
        ]}
      >
        <Form
          name="target-form"
          layout="vertical"
          onFinish={handleAddEdit}
          style={{ width: "100%", margin: "0" }}
          form={LevelConfigurationForm}
        >
          <Row gutter={[20, 10]}>
            <Col span={24}>
              <h3>{t("basic_level")}</h3>
            </Col>
            <Col span={8}>
              <Form.Item
                name="basic_target"
                label={t("target")}
                rules={[
                  {
                    required: true,
                    message: t("validate_target_required"),
                  },
                ]}
                hasFeedback
              >
                <InputNumber style={{ width: "100%" }} disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="basic_retain_rate"
                label={t("retain_rate")}
                rules={[
                  {
                    required: true,
                    message: t("validate_retain_rate_required"),
                  },
                ]}
                hasFeedback
              >
                <InputNumber
                  style={{ width: "100%" }}
                  disabled
                  addonAfter="%"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="basic_commission"
                label={t("commission")}
                rules={[
                  {
                    required: true,
                    message: t("validate_commission_required"),
                  },
                ]}
              >
                <InputNumber style={{ width: "100%" }} addonAfter="%" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <h3>{t("pro_level")}</h3>
            </Col>
            <Col span={8}>
              <Form.Item
                name="pro_target"
                label={t("target")}
                rules={[
                  {
                    required: true,
                    message: t("validate_target_required"),
                  },
                ]}
                hasFeedback
              >
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="pro_retain_rate"
                label={t("retain_rate")}
                rules={[
                  {
                    required: true,
                    message: t("validate_retain_rate_required"),
                  },
                ]}
                hasFeedback
              >
                <InputNumber style={{ width: "100%" }} addonAfter="%" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="pro_commission"
                label={t("commission")}
                rules={[
                  {
                    required: true,
                    message: t("validate_commission_required"),
                  },
                ]}
                hasFeedback
              >
                <InputNumber style={{ width: "100%" }} addonAfter="%" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default LevelConfiguration;
