import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "../pages/Content/Home/Home";
import Companies from "../pages/Content/Companies/Companies";
import Company from "../pages/Content/Companies/Company";
import Regions from "../pages/Content/Regions/Regions";
import Staffs from "../pages/Content/Staffs/Staffs";
import IssueTypes from "../pages/Content/IssueTypes/IssueTypes";
import Helpdesk from "../pages/Content/Helpdesk/Helpdesk";
import Cities from "../pages/Content/Cities/Cities";
import Managers from "../pages/Content/Managers/Managers";
import {
  HomeOutlined,
  AppstoreOutlined,
  UserOutlined,
  GlobalOutlined,
  CustomerServiceOutlined,
  IssuesCloseOutlined,
  DeploymentUnitOutlined,
  ApartmentOutlined,
  BarChartOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { filterPagesByAddons } from "../utils";
import { AppContext } from "../context/AppContextProvider";
import BusinessTypeVerifications from "../pages/Content/BusinessTypeVerifications/BusinessTypeVerifications";
import AllActivities from "../pages/Content/AllActivities/AllActivities";
import ActivityType from "../pages/Content/ActivityType/ActivityType";
import Features from "../pages/Content/Features/Features";
import Subscriptions from "../pages/Content/Subscriptions/Subscriptions";
import SystemSettings from "../pages/Content/SystemSettings/SystemSettings";
import SubscriptionPlans from "../pages/Content/SubscriptionPlans/SubscriptionPlans";
import Addons from "../pages/Content/Addons/Addons";
// import Referral from "../pages/Content/Referral/Referral";
// import ReferralDetail from "../pages/Content/Referral/ReferralDetail";
import Reseller from "../pages/Content/Reseller/Reseller";
import ResellerDetail from "../pages/Content/Reseller/ResellerDetail";
import PayoutDetails from "../pages/Content/Reseller/PayoutDetails";

export const ContentPages = [
  {
    path: "/",
    name: "home",
    text: "menu_home",
    component: Home,
    icon: HomeOutlined,
    showInSider: true,
  },
  {
    path: "/companies",
    name: "companies",
    text: "menu_companies",
    component: Companies,
    icon: AppstoreOutlined,
    showInSider: true,
  },
  {
    path: "/companies/:companyId",
    name: "companies_company",
    text: "menu_companies_company",
    component: Company,
    icon: false,
    showInSider: false,
  },
  {
    path: "/subscriptions",
    name: "subscriptions",
    text: "menu_subscriptions",
    component: Subscriptions,
    icon: AppstoreOutlined,
    showInSider: true,
  },
  {
    path: "/activities",
    name: "activities",
    text: "menu_activities",
    component: AllActivities,
    icon: ApartmentOutlined,
    showInSider: true,
  },
  {
    path: "/helpdesk",
    name: "helpdesk",
    text: "menu_help_desk",
    component: Helpdesk,
    icon: CustomerServiceOutlined,
    showInSider: true,
  },
  {
    path: "/addons",
    name: "addons",
    text: "menu_addons",
    component: Addons,
    icon: DeploymentUnitOutlined,
    showInSider: true,
  },
  {
    name: "configuration",
    text: "menu_configuration",
    icon: AppstoreOutlined,
    showInSider: true,
    subMenus: [
      {
        path: "/regions",
        name: "region",
        text: "menu_regions",
        component: Regions,
        icon: GlobalOutlined,
        showInSider: true,
      },
      {
        path: "/regions/:regionId",
        name: "cities",
        text: "menu_cities",
        component: Cities,
        icon: false,
        showInSider: false,
      },
      {
        path: "/business-type-verifications",
        name: "business_type_verifications",
        text: "menu_business_type_verifications",
        component: BusinessTypeVerifications,
        icon: ApartmentOutlined,
        showInSider: true,
      },
      {
        path: "/issue-types",
        name: "issue_types",
        text: "menu_issue_types",
        component: IssueTypes,
        icon: IssuesCloseOutlined,
        showInSider: true,
      },
      {
        path: "/activity-type",
        name: "activity_type",
        text: "menu_activity_type",
        component: ActivityType,
        icon: GlobalOutlined,
        showInSider: true,
      },
      {
        path: "/features",
        name: "features",
        text: "menu_features",
        component: Features,
        icon: GlobalOutlined,
        showInSider: true,
      },
      {
        path: "/system-settings",
        name: "system_settings",
        text: "menu_system_settings",
        component: SystemSettings,
        icon: SettingOutlined,
        showInSider: true,
      },
      {
        path: "/subscription-plans",
        name: "subscription_plans",
        text: "menu_subscription_plans",
        component: SubscriptionPlans,
        icon: DeploymentUnitOutlined,
        showInSider: true,
      },
    ],
  },
  {
    path: "/resellers",
    name: "resellers",
    text: "menu_resellers",
    component: Reseller,
    icon: UsergroupAddOutlined,
    showInSider: true,
  },
  {
    path: "/resellers/:resellerId",
    name: "reseller_detail",
    text: "menu_reseller_detail",
    component: ResellerDetail,
    icon: false,
    showInSider: false,
  },
  {
    path: "/resellers/:resellerId/payout_history/:payoutId",
    name: "payout_detail",
    text: "menu_payout_detail",
    component: PayoutDetails,
    icon: false,
    showInSider: false,
  },
  // {
  //   path: "/referrals",
  //   name: "referral",
  //   text: "menu_referrals",
  //   component: Referral,
  //   icon: DeploymentUnitOutlined,
  //   showInSider: true,
  // },
  // {
  //   path: "/referrals/:referralId",
  //   name: "referral_detail",
  //   text: "menu_referral_detail",
  //   component: ReferralDetail,
  //   icon: false,
  //   showInSider: false,
  // },
  {
    name: "access_management",
    text: "menu_access_management",
    icon: BarChartOutlined,
    showInSider: true,
    subMenus: [
      {
        path: "/managers",
        name: "manager",
        text: "menu_managers",
        component: Managers,
        icon: DeploymentUnitOutlined,
        showInSider: true,
      },
      {
        path: "/staffs",
        name: "staff",
        text: "menu_staffs",
        component: Staffs,
        icon: UserOutlined,
        showInSider: true,
      },
    ],
  },
];

const AppRoutes = () => {
  const { authUser } = useContext(AppContext);
  return (
    <>
      <Routes>
        <Route exact path="/home" element={<Navigate to="/" replace />} />
        <Route path="/" element={<Home />} />

        {filterPagesByAddons(ContentPages, authUser).map(function (page, i) {
          if (page.component) {
            return (
              <Route
                exact
                path={page.path}
                name={page.name}
                element={<page.component />}
                key={page.name}
              />
            );
          } else {
            let subRoutes =
              page.subMenus &&
              filterPagesByAddons(page.subMenus, authUser).map(function (
                subPage,
                i
              ) {
                if (subPage.component) {
                  return (
                    <Route
                      exact
                      path={subPage.path}
                      name={subPage.name}
                      element={<subPage.component />}
                      key={subPage.name}
                    />
                  );
                }
                return false;
              });
            return subRoutes;
          }
        })}
      </Routes>
    </>
  );
};

export default AppRoutes;
